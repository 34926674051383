import Footer from "../../components/Footer";
import Header from "../../components/Header";

const TermsAndCondition = () => {
  return (
    <div>
      <Header />
      <section className="kt-platform bg-tranh">
        <div className="container">
          <div className="row">
            <div className="col-md-12  mb-5">
              <h1 className="text-center">Terms & Condtions</h1>
            </div>

            <div className="col-md-12  mb-5">
              <h5>1. Acceptance of Terms</h5>
              <p>
                By accessing and using our website, you agree to be bound by
                these Terms and Conditions. If you do not agree with any part of
                these terms, please refrain from using our website.
              </p>
            </div>
            <div className="col-md-12  mb-5">
              <h5>2. Intellectual Property</h5>
              <p>
                All content and materials on our website, including but not
                limited to text, graphics, logos, images, and software, are the
                property of Krysznatec or its licensors and are protected by
                copyright, trademark, and other intellectual property laws. You
                may not use, copy, reproduce, modify, or distribute any content
                from our website without our prior written consent.
              </p>
            </div>
            <div className="col-md-12  mb-5">
              <h5>3. Use of Our Website</h5>
              <p>
                You may use our website for lawful purposes and in accordance
                with these Terms and Conditions. You agree not to:
              </p>

              <p>
                <ul>
                  <li>3.1. Violate any applicable laws or regulations.</li>
                  <li>
                    3.2. Attempt to gain unauthorized access to our website or
                    any portion of it.
                  </li>
                  <li>
                    3.3. Interfere with the proper functioning of our website.
                  </li>
                </ul>
              </p>
            </div>
            <div className="col-md-12  mb-5">
              <h5>4. Disclaimer of Warranties</h5>
              <p>
                Our website is provided on an "as is" and "as available" basis.
                We do not make any warranties or representations of any kind,
                express or implied, regarding the operation, availability,
                accuracy, completeness, or reliability of our website.
              </p>
            </div>
            <div className="col-md-12  mb-5">
              <h5>5. Limitation of Liability</h5>
              <p>
                In no event shall Krysznatec, its officers, directors,
                employees, or affiliates be liable for any direct, indirect,
                special, consequential, or incidental damages arising out of or
                in connection with your use of our website.
              </p>
            </div>
            <div className="col-md-12  mb-5">
              <h5>6. Links to Third-Party Websites</h5>
              <p>
                Our website may contain links to third-party websites for your
                convenience. We do not endorse or control the content of these
                websites, and we are not responsible for their practices or
                policies.
              </p>
            </div>
            <div className="col-md-12  mb-5">
              <h5>7. Modifications to Terms and Conditions</h5>
              <p>
                We may update these Terms and Conditions from time to time. Any
                changes will be posted on this page, and the date of the latest
                revision will be indicated at the top of the document.
              </p>
            </div>
            <div className="col-md-12  mb-5">
              <h5>8. Governing Law and Jurisdiction</h5>
              <p>
                These Terms and Conditions shall be governed by and construed in
                accordance with the laws of [Your Jurisdiction]. Any disputes
                arising under or in connection with these terms shall be subject
                to the exclusive jurisdiction of the courts of [Your
                Jurisdiction].
              </p>
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </div>
  );
};

export default TermsAndCondition;
