import React from "react";
import { MdComputer, MdPhone } from "react-icons/md";
import Pricing from "../../assets/ServicesBanner.jpg";
import rightimgvector from "../../assets/vector1.svg";
import rightimgvectortwo from "../../assets/Vector2.svg";
import Footer from "../../components/Footer";
import Header from "../../components/Header";
import "./style.scss";

const Services = () => {
  return (
    <div>
      <Header />

      <section className="banner mt-0 mb-5">
        <div className="banner-img">
          <div className="col-md-12 ps-0 pe-0">
            <div className="slider border-no">
              <img src={Pricing} alt="Services Banner" />
            </div>
          </div>
        </div>
      </section>
      <section className="kt-platform bg-tranh">
        <div className="container">
          <div className="row">
            <div className="col-md-12 text-center mb-5">
              <div class="title">
                <h1 className="header-color">Our Services</h1>
              </div>
            </div>

            <div className="row text-container">
              <div className="col-md-12 text-start subheader-color mb-5 pb-4">
                <div style={{ margin: "0px auto", textAlign: "justify" }}>
                  <p>
                    At Kryszna Tec we offer you, The KT Platform, we offer a
                    wide range of comprehensive technology services to make you
                    understand where their technology is, and how much each part
                    of their business consumes to empower your business and
                    drive growth. With our expertise and commitment to
                    excellence, we deliver innovativeplatform with{" "}
                    <b>MultiCloud hosting services</b> tailored to your specific
                    needs. Explore our services below:
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="kt-platform support-bg">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-md-12 text-center mb-5">
              <div class="title">
                <h3 className="header-color">For Clients</h3>
              </div>
            </div>
            <div className="col-md-6 text-start mb-5 pb-4">
              <div className="content-text right-img">
                <img src={rightimgvector} className="w-75" alt="" />
              </div>
            </div>

            <div className="col-md-6 text-center mb-5 pb-4">
              <ul className="kt-list">
                <li className="subheader-color">
                  <strong className="subheader content-display">
                    Implementation:
                  </strong>{" "}
                  We seamlessly integrate our On Premise, Cloud and SaaS service
                  vendor managementinto organizations ranging from 100 employees
                  to enterprise level, ensuring a smooth transition and optimal
                  results.
                </li>
                <li className="subheader-color">
                  <strong className="subheader content-display">
                    Cost Savings:
                  </strong>{" "}
                  Our expertise enables clients to achieve significant savings,
                  with potential budget reductions from 15% of budget. We
                  analyze and optimize their expenses, maximizing efficiency and
                  profitability by complete technology cost analysis with
                  simulations that deliver realized savings.
                </li>
                <li className="subheader-color">
                  <strong className="subheader content-display">
                    Simulation of Solutions:
                  </strong>
                  Through advanced simulations through UI, we help clients
                  explore new and existing solutions that align with their
                  business initiatives. Whether it's digital transformation,
                  cloud transformation, AI or technology initiatives, we enable
                  clients to drive their vision forward.
                </li>
                <li className="subheader-color">
                  <strong className="subheader content-display">
                    Buying System Transformation:
                  </strong>{" "}
                  We go beyond simple solutions and transform the entire buying
                  system, revolutionizing procurement processes and driving
                  operational excellence with a click of a button.
                </li>
                <li className="subheader-color">
                  <strong className="subheader content-display">
                    Streamlined Purchasing:
                  </strong>{" "}
                  By leveraging our strategies and tools, clients save time and
                  money on long buying processes, allowing them to focus on core
                  business activities.
                </li>
                <li className="subheader-color">
                  <strong className="subheader content-display">
                    Comprehensive Visibility:
                  </strong>{" "}
                  Gain a holistic view of your cloud infrastructure, Software as
                  a Service (SaaS) applications, and on-premise business
                  operations. We provide insights that enable informed
                  decision-making.
                </li>
              </ul>
            </div>
          </div>
        </div>
      </section>

      <section className="kt-platform bg-tranh support-bgnew  pt-0">
        <div className="container pt-5">
          <div className="row align-items-center">
            <div className="col-md-12 text-center mb-5">
              <div class="title">
                <h3 className="header-color">For Suppliers</h3>
              </div>
            </div>

            <div className="col-md-6 text-center mb-5 pb-4">
              <ul className="kt-list">
                <li className="subheader-color">
                  <strong className="subheader content-display">
                    Direct Solution Delivery:
                  </strong>{" "}
                  We facilitate the direct delivery of suppliers' solutions to
                  the stakeholders who control the budget. This ensures a
                  streamlined and efficient procurement process.
                </li>
                <li className="subheader-color">
                  <strong className="subheader content-display">
                    Online Purchase Capability:
                  </strong>{" "}
                  Our platform empowers suppliers by offering their solutions
                  for online purchase. This convenient and accessible channel
                  expands sales opportunities and enhances customer
                  satisfaction.
                </li>
                <li className="subheader-color">
                  <strong className="subheader content-display">
                    Upgrades and Refreshes:
                  </strong>{" "}
                  We help suppliers drive sales within their existing client
                  base by providing support for upgrades and refreshes. This
                  ensures continued growth and customer loyalty.
                </li>
                <li className="subheader-color">
                  <strong className="subheader content-display">
                    New Business Exposure:
                  </strong>{" "}
                  With our services, suppliers can expand their presence in new
                  business domains where they currently have no footprint. We
                  unlock untapped markets, generating new opportunities for
                  success.
                </li>
              </ul>
            </div>
            <div className="col-md-6 text-start mb-5 pb-4">
              <div className="content-text right-img">
                <img src={rightimgvectortwo} className="w-75" alt="" />
              </div>
            </div>
          </div>
        </div>
      </section>

      <section>
        <div className="col-md-12">
          <div className="content-text w-100 ms-auto text-center">
            <p className="container subheader-color">
              <MdPhone style={{ color: "#2B6CB0" }} /> Contact us today to learn
              more about our services and how we can help transform your
              business through innovative technology solutions.
            </p>
            <p className="container subheader-color">
              <MdComputer style={{ color: "#2F855A" }} /> Login onto the KT
              Platform from your desktop or mobile and we are able to bring in
              both on premise and global providers into a logical business view
              of how you are consuming your IT budget or we will setup alerts
              that will notify you when you need to action an item.
            </p>
          </div>
        </div>
      </section>

      <Footer />
    </div>
  );
};

export default Services;
