import React, { useState } from "react";
import { MdFrontHand, MdPhone } from "react-icons/md";
import video from "../../assets/video-version.MP4";
import ElevatedCard from "../../components/ElevatedCard";
import { HStack } from "../../components/Flex";
import Footer from "../../components/Footer";
import Header from "../../components/Header";
import ListPoint from "../../components/ListPoint";
import "./style.scss";

const Home = () => {
  const [muted, setMuted] = useState(true);

  const handleClick = () => setMuted((prevValue) => !prevValue);

  return (
    <div>
      <Header />

      <section className="banner mt-0 mb-5">
        <div className="banner-img">
          <div className="col-md-12">
            <div className="slider position-relative">
              <video src={video} controls autoPlay muted={muted}></video>
              <button onClick={handleClick} className="unmute-btn">
                {muted ? "Unmute" : "Mute"}
              </button>
            </div>
          </div>
        </div>
      </section>

      <section className="kt-platform bgone">
        <div className="container mt-5">
          <div className="row">
            <div className="col-md-12 text-center mb-5">
              <div class="title header-color">
                <h1>Welcome to The KT Platform</h1>
              </div>
            </div>
            <div className="row text-container">
              <div className="col-md-12 text-start mb-5 pb-4">
                <div style={{ margin: "0px auto", textAlign: "justify" }}>
                  <p className="subheader-color">
                    At Kryszna Tec we are passionate about digitally delivering
                    cutting-edge technology solutions that drive your business
                    and revenue upward. With The KT Platform, our expertise and
                    dedication, we provide a comprehensive on-premise and true
                    multi cloud solution to meet your unique needs. From
                    hardware, cloud computing, AI, and SaaS integration
                    solutions to cost management and beyond, we have you
                    covered. Instantly compare across multiple vendors what is
                    within budget, and what is available and digitally acquire
                    the power of technology across multiple vendors for your
                    global business success.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section>
        <div className="container mt-5">
          <div className="row">
            <div className="col-md-12 text-center mb-5">
              <div class="title">
                <h2 className="header-color">Services We Offer</h2>
              </div>
            </div>
            <div className="row text-container">
              <div className="col-md-12 text-start mb-2">
                <p className="subheader-color">
                  We are dedicated to providing cutting-edge solutions and
                  services that cater to both clients and suppliers, enabling
                  seamless collaboration and driving business growth. Our focus
                  is on implementing innovative strategies that meet current
                  business standards, ensuring success for organizations of all
                  sizes, from 100-employee companies to enterprises.
                </p>
              </div>
            </div>

            <div className="row">
              <div className="col-md-6 text-start mb-4">
                <ElevatedCard
                  title="Technology Cost Management"
                  content={
                    <>
                      <p>
                        Take control of your technology expenses and optimize
                        your IT budget.Get your on premise,{" "}
                        <strong>cost management</strong> information posted
                        directly to global business units by cost centre and
                        save from 15%.
                      </p>
                    </>
                  }
                />
              </div>

              <div className="col-md-6 text-start mb-4">
                <ElevatedCard
                  title="The KT Simulator"
                  content={
                    <>
                      <p>
                        The KT Simulator is used to autonomously bring
                        simulations to you. The processes that runs simulations
                        is based on your business growth, strategy targets as
                        well as upgrades and refresh in the background or run
                        simulations though our UI.
                      </p>
                    </>
                  }
                />
              </div>
              <div className="col-md-6 text-start mb-4">
                <div className="content-text">
                  <ElevatedCard
                    title="Acquire the solution with a click of a button"
                    content={
                      <>
                        <p>
                          Drive seamless connectivity and streamline business
                          processeswith OEM, compliance and process. With a
                          click you can kick of the process to GET the solution
                          from your{" "}
                          <strong>Cloud and SaaS vendor partnerships.</strong>
                        </p>
                      </>
                    }
                  />
                </div>
              </div>
              <div className="col-md-6 text-start mb-4">
                <ElevatedCard
                  title="Cloud Computing Solutions"
                  content={
                    <>
                      <p>
                        Unlock the power of the cloud with our
                        comprehensivesolution tailored to your needs by our true
                        multi cloud, hybrid{" "}
                        <strong>cloud and SaaS vendors.</strong>
                      </p>
                    </>
                  }
                />
              </div>
              <div className="col-md-6 text-start mb-4">
                <ElevatedCard
                  title="IT Consultancy and Advisory"
                  content={
                    <>
                      <p>
                        Get strategic guidance and expertise for your technology
                        initiatives. The KT Platform will notify business of new
                        solutions as they are created globally throughout the
                        business with granular pricing.
                      </p>
                    </>
                  }
                />
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="kt-platform bgone">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-md-12 text-start mb-5 pb-4">
              <div>
                <h2 className="header-color text-center">
                  Our Work Methodology
                </h2>
                <h5 className="subheader text-center my-4">
                  Here's a breakdown of what we offer to each side:
                </h5>
              </div>
            </div>

            <div className="col-md-8 text-start mb-5 pb-4">
              <ElevatedCard
                content={
                  <>
                    <strong className="header-color">For Clients:</strong>
                    <p className="subheader-color">
                      Implementation for clients of all sizes, ranging from 100
                      employees to enterprise level.
                    </p>

                    <strong>Client deliverables include:</strong>
                    <ul className="kt-list mt-2">
                      <li className="gray--text">
                        Achieve up to 15% savings on their budget.
                      </li>
                      <li className="gray--text">
                        Comprehensive transformation of their entire buying
                        systems.
                      </li>
                      <li className="gray--text">
                        Cost savings on lengthy buying processes.
                      </li>
                      <li className="gray--text">
                        Gain visibility into their cloud, Software as a Service
                        (SaaS),AI and on-premise business operations.
                      </li>
                      <li className="gray--text">
                        Simulation of new and existing solutions and
                        technologoies to drive business initiatives like
                        artificial intelligence, digital transformation, cloud
                        transformation, and technology initiatives.
                      </li>
                      <li className="gray--text">
                        The ability to GET the solution with a click of a
                        button.
                      </li>
                    </ul>
                  </>
                }
              />
            </div>
            <div className="col-md-4 text-start mb-5 pb-4">
              <ElevatedCard
                content={
                  <>
                    <strong className="header-color">For Suppliers:</strong>

                    <ul className="kt-list mt-4">
                      <li className="gray--text">
                        Direct delivery of solutions to the stakeholders who
                        control the budget.
                      </li>
                      <li className="gray--text">
                        The ability to offer solutions for online purchase,
                        enhancing convenience and accessibility.
                      </li>
                      <li className="gray--text">
                        Drive sales within existing clients through upgrades and
                        refreshes.
                      </li>
                      <li className="gray--text">
                        Expand exposure in business domains where suppliers
                        currently have no presence.
                      </li>
                    </ul>
                  </>
                }
              />
            </div>
          </div>
        </div>
      </section>

      <section className="kt-platform bg-gray pt-5 bgone bgtwo">
        <div className="container pt-5">
          <div className="row align-items-center">
            <div className="col-md-6 text-start mb-5 pb-4">
              <div className="content-text right-img">
                {/* <img src={dash1} alt="" /> */}

                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  xlink="http://www.w3.org/1999/xlink"
                  width="623px"
                  height="771px"
                  viewBox="0 0 623 771"
                >
                  <title>dashboard img2</title>
                  <defs>
                    <filter
                      x="-3.7%"
                      y="-1.7%"
                      width="107.4%"
                      height="103.3%"
                      filterUnits="objectBoundingBox"
                      id="filter-1"
                    >
                      <feOffset
                        dx="0"
                        dy="2"
                        in="SourceAlpha"
                        result="shadowOffsetOuter1"
                      />
                      <feGaussianBlur
                        stdDeviation="2"
                        in="shadowOffsetOuter1"
                        result="shadowBlurOuter1"
                      />
                      <feColorMatrix
                        values="0 0 0 0 0   0 0 0 0 0   0 0 0 0 0  0 0 0 0.5 0"
                        type="matrix"
                        in="shadowBlurOuter1"
                        result="shadowMatrixOuter1"
                      />
                      <feMerge>
                        <feMergeNode in="shadowMatrixOuter1" />
                        <feMergeNode in="SourceGraphic" />
                      </feMerge>
                    </filter>
                    <path
                      d="M9,0 C10.104,0 11,0.896 11,2 C11,3.104 10.104,4 9,4 C7.896,4 7,3.104 7,2 C7,0.896 7.896,0 9,0 Z M16,0 C17.104,0 18,0.896 18,2 C18,3.104 17.104,4 16,4 C14.896,4 14,3.104 14,2 C14,0.896 14.896,0 16,0 Z M2,0 C3.104,0 4,0.896 4,2 C4,3.104 3.104,4 2,4 C0.896,4 0,3.104 0,2 C0,0.896 0.896,0 2,0 Z"
                      id="path-2"
                    />
                  </defs>
                  <g
                    id="Page-1"
                    stroke="none"
                    stroke-width="1"
                    fill="none"
                    fill-rule="evenodd"
                  >
                    <g
                      id="dashboard-img2"
                      transform="translate(0.000000, 10.596334)"
                    >
                      <circle
                        id="Oval"
                        fill="#1AE1F5"
                        cx="268"
                        cy="444.403666"
                        r="268"
                      />
                      <g
                        id="Widget"
                        filter="url(#filter-1)"
                        transform="translate(285.270551, 375.066022) rotate(6.000000) translate(-285.270551, -375.066022) translate(122.270551, 15.066022)"
                      >
                        <g id="Elements-/-BG" fill="#FFFFFF">
                          <path
                            d="M12.819426,-5.17920154e-16 L313.180574,5.17920154e-16 C317.638168,-3.00926524e-16 319.254599,0.464128056 320.884229,1.33566352 C322.513858,2.20719898 323.792801,3.48614185 324.664336,5.11577148 C325.535872,6.74540111 326,8.3618323 326,12.819426 L326,707.180574 C326,711.638168 325.535872,713.254599 324.664336,714.884229 C323.792801,716.513858 322.513858,717.792801 320.884229,718.664336 C319.254599,719.535872 317.638168,720 313.180574,720 L12.819426,720 C8.3618323,720 6.74540111,719.535872 5.11577148,718.664336 C3.48614185,717.792801 2.20719898,716.513858 1.33566352,714.884229 C0.464128056,713.254599 2.00617683e-16,711.638168 -3.45280103e-16,707.180574 L3.45280103e-16,12.819426 C-2.00617683e-16,8.3618323 0.464128056,6.74540111 1.33566352,5.11577148 C2.20719898,3.48614185 3.48614185,2.20719898 5.11577148,1.33566352 C6.74540111,0.464128056 8.3618323,3.00926524e-16 12.819426,-5.17920154e-16 Z"
                            id="Color"
                          />
                        </g>
                        <g
                          id="Elements-/-Info"
                          transform="translate(30.099691, 299.670073)"
                        >
                          <circle
                            id="Color"
                            fill="#0010F7"
                            cx="7"
                            cy="10"
                            r="7"
                          />
                          <text
                            id="Text"
                            font-family="Aller-Regular, Aller"
                            font-size="14"
                            font-weight="normal"
                            line-spacing="20"
                            fill="#000000"
                          >
                            <tspan x="20" y="13">
                              Software
                            </tspan>
                          </text>
                        </g>
                        <g
                          id="Elements-/-Info-Copy"
                          transform="translate(137.170906, 301.028943)"
                        >
                          <circle
                            id="Color"
                            fill="#1BE7FF"
                            cx="7"
                            cy="10"
                            r="7"
                          />
                          <text
                            id="Text"
                            font-family="Aller-Regular, Aller"
                            font-size="14"
                            font-weight="normal"
                            line-spacing="20"
                            fill="#000000"
                          >
                            <tspan x="20" y="13">
                              Cloud Services
                            </tspan>
                          </text>
                        </g>
                        <text
                          id="By-Category"
                          font-family="Aller-Regular, Aller"
                          font-size="18"
                          font-weight="normal"
                          fill="#000000"
                        >
                          <tspan x="33" y="375">
                            By Category
                          </tspan>
                        </text>
                        <g
                          id="Group-15"
                          transform="translate(33.000000, 407.000000)"
                        >
                          <text
                            id="Marketing-Division"
                            font-family="Aller-Regular, Aller"
                            font-size="18"
                            font-weight="normal"
                            fill="#000000"
                          >
                            <tspan x="0" y="17">
                              Marketing Division
                            </tspan>
                          </text>
                          <text
                            id="Employee-Expenses"
                            font-family="Aller-Light, Aller"
                            font-size="16"
                            font-weight="300"
                            fill="#878F92"
                          >
                            <tspan x="0" y="42">
                              Employee Expenses
                            </tspan>
                          </text>
                        </g>
                        <g
                          id="DownArrow_lighter"
                          transform="translate(292.000000, 428.714286) scale(1, -1) rotate(-90.000000) translate(-292.000000, -428.714286) translate(282.000000, 423.000000)"
                          fill="#000000"
                          fill-rule="nonzero"
                        >
                          <polygon
                            id="Path"
                            points="10 11.4285714 0 1.42857143 0 0 1.42857143 0 10 8.57142857 18.5714286 0 20 0 20 1.42857143"
                          />
                        </g>
                        <g
                          id="Group-15-Copy"
                          transform="translate(33.000000, 487.000000)"
                        >
                          <text
                            id="On-Premise-software"
                            font-family="Aller-Regular, Aller"
                            font-size="18"
                            font-weight="normal"
                            fill="#000000"
                          >
                            <tspan x="0" y="17">
                              On Premise software
                            </tspan>
                          </text>
                          <text
                            id="Security-Solutions"
                            font-family="Aller-Light, Aller"
                            font-size="16"
                            font-weight="300"
                            fill="#878F92"
                          >
                            <tspan x="0" y="42">
                              Security Solutions
                            </tspan>
                          </text>
                        </g>
                        <g
                          id="DownArrow_lighter-Copy-4"
                          transform="translate(292.000000, 508.714286) scale(1, -1) rotate(-90.000000) translate(-292.000000, -508.714286) translate(282.000000, 503.000000)"
                          fill="#000000"
                          fill-rule="nonzero"
                        >
                          <polygon
                            id="Path"
                            points="10 11.4285714 0 1.42857143 0 0 1.42857143 0 10 8.57142857 18.5714286 0 20 0 20 1.42857143"
                          />
                        </g>
                        <g
                          id="Group-15-Copy-2"
                          transform="translate(33.000000, 567.000000)"
                        >
                          <text
                            id="Software-as-a-Servic"
                            font-family="Aller-Regular, Aller"
                            font-size="18"
                            font-weight="normal"
                            fill="#000000"
                          >
                            <tspan x="0" y="17">
                              Software as a Service (SaaS)
                            </tspan>
                          </text>
                          <text
                            id="Last-Financial-years"
                            font-family="Aller-Light, Aller"
                            font-size="16"
                            font-weight="300"
                            fill="#878F92"
                          >
                            <tspan x="0" y="42">
                              Last Financial years Expenses
                            </tspan>
                          </text>
                        </g>
                        <g
                          id="DownArrow_lighter-Copy-5"
                          transform="translate(292.000000, 588.714286) scale(1, -1) rotate(-90.000000) translate(-292.000000, -588.714286) translate(282.000000, 583.000000)"
                          fill="#000000"
                          fill-rule="nonzero"
                        >
                          <polygon
                            id="Path"
                            points="10 11.4285714 0 1.42857143 0 0 1.42857143 0 10 8.57142857 18.5714286 0 20 0 20 1.42857143"
                          />
                        </g>
                        <g
                          id="Group-15-Copy-3"
                          transform="translate(33.000000, 647.000000)"
                        >
                          <text
                            id="Marketing-Division-T"
                            font-family="Aller-Regular, Aller"
                            font-size="18"
                            font-weight="normal"
                            fill="#000000"
                          >
                            <tspan x="0" y="17">
                              Marketing Division Total Cost
                            </tspan>
                          </text>
                          <text
                            id="Last-Financial-years"
                            font-family="Aller-Light, Aller"
                            font-size="16"
                            font-weight="300"
                            fill="#878F92"
                          >
                            <tspan x="0" y="42">
                              Last Financial years Total Costs
                            </tspan>
                          </text>
                        </g>
                        <g
                          id="DownArrow_lighter-Copy-6"
                          transform="translate(292.000000, 668.714286) scale(1, -1) rotate(-90.000000) translate(-292.000000, -668.714286) translate(282.000000, 663.000000)"
                          fill="#000000"
                          fill-rule="nonzero"
                        >
                          <polygon
                            id="Path"
                            points="10 11.4285714 0 1.42857143 0 0 1.42857143 0 10 8.57142857 18.5714286 0 20 0 20 1.42857143"
                          />
                        </g>
                        <g
                          id="Group-9"
                          transform="translate(61.272398, 68.663990)"
                        >
                          <circle
                            id="Color"
                            fill="#F9F9F9"
                            cx="101.727602"
                            cy="98.006083"
                            r="98"
                          />
                          <path
                            d="M101.707623,104.561099 C97.790398,104.561099 94.61486,101.381671 94.61486,97.4596493 C94.61486,93.5376272 97.790398,90.3582 101.707623,90.3582 C147.743128,90.3582 185.12983,53.1325724 185.400736,7.0658472 C185.4238,3.14389304 188.61798,-0.016759083 192.535137,0.00620735407 C196.452293,0.0294252591 199.609079,3.22751719 199.586141,7.14947135 C199.26918,61.0263696 155.546076,104.561099 101.707623,104.561099 Z"
                            id="Oval"
                            fill="#1BE7FF"
                            transform="translate(147.100563, 52.283591) scale(1, -1) translate(-147.100563, -52.283591) "
                          />
                          <path
                            d="M105.294829,191.371749 C101.377605,191.371749 98.2020668,188.192322 98.2020668,184.2703 C98.2020668,180.348277 101.377605,177.16885 105.294829,177.16885 C151.330334,177.16885 188.717037,139.943223 188.987943,93.8764975 C189.011007,89.9545433 192.205187,86.7938912 196.122343,86.8168576 C200.0395,86.8400755 203.196286,90.0381675 203.173348,93.9601216 C202.856387,147.83702 159.133283,191.371749 105.294829,191.371749 Z"
                            id="Oval-Copy"
                            fill="#0010F7"
                            transform="translate(150.687769, 139.094241) scale(-1, -1) rotate(-185.000000) translate(-150.687769, -139.094241) "
                          />
                          <text
                            id="Total"
                            font-family="Aller-Regular, Aller"
                            font-size="40"
                            font-weight="normal"
                            fill="#636E72"
                          >
                            <tspan x="56.7076021" y="109.006083">
                              Total
                            </tspan>
                          </text>
                          <text
                            id="$4,256"
                            font-family="Aller-Regular, Aller"
                            font-size="20"
                            font-weight="normal"
                            fill="#000000"
                          >
                            <tspan x="70.0976021" y="138.006083">
                              $4,256
                            </tspan>
                          </text>
                        </g>
                        <g
                          id="Icons-/-More-/-Horizontal"
                          transform="translate(275.000000, 43.000000)"
                        >
                          <mask id="mask-3" fill="white">
                            <use href="#path-2" />
                          </mask>
                          <use
                            id="Color"
                            fill="#000000"
                            opacity="0.200000003"
                            href="#path-2"
                          />
                        </g>
                        <text
                          id="Expenses"
                          font-family="Aller-Regular, Aller"
                          font-size="22"
                          font-weight="normal"
                          fill="#2D3436"
                        >
                          <tspan x="30" y="51">
                            Expenses
                          </tspan>
                        </text>
                      </g>
                      <g
                        id="Group-17"
                        transform="translate(457.000000, 463.403666) rotate(-4.000000) translate(-457.000000, -463.403666) translate(294.000000, 422.403666)"
                      >
                        <rect
                          id="Rectangle"
                          stroke="#E8E9EC"
                          fill="#FFFFFF"
                          x="0"
                          y="0"
                          width="326"
                          height="82"
                          rx="12"
                        />
                        <g
                          id="Group-16"
                          transform="translate(31.000000, 18.000000)"
                        >
                          <g
                            id="Group-15-Copy-4"
                            transform="translate(-0.000000, -0.000000)"
                          >
                            <text
                              id="Marketing-Division-T"
                              font-family="Aller-Regular, Aller"
                              font-size="18"
                              font-weight="normal"
                              fill="#000000"
                            >
                              <tspan x="0" y="17">
                                Marketing Division Total Cost
                              </tspan>
                            </text>
                            <text
                              id="Last-Financial-years"
                              font-family="Aller-Light, Aller"
                              font-size="16"
                              font-weight="300"
                              fill="#878F92"
                            >
                              <tspan x="-9.09494702e-13" y="42">
                                Last Financial years Total Costs
                              </tspan>
                            </text>
                          </g>
                          <g
                            id="DownArrow_lighter-Copy-7"
                            transform="translate(259.000000, 21.714286) scale(1, -1) rotate(-90.000000) translate(-259.000000, -21.714286) translate(249.000000, 16.000000)"
                            fill="#000000"
                            fill-rule="nonzero"
                          >
                            <polygon
                              id="Path"
                              points="10 11.4285714 0 1.42857143 0 0 1.42857143 0 10 8.57142857 18.5714286 0 20 0 20 1.42857143"
                            />
                          </g>
                        </g>
                      </g>
                    </g>
                  </g>
                </svg>
              </div>
            </div>

            <div className="col-md-6 text-start mb-5 pb-4">
              <div className="content-text w-100 ms-auto">
                <h2 className="header-color mb-4">
                  Why Choose The KT Platform?
                </h2>
                <div className="why-choose-kt-list-container subheader-color">
                  <ul className="kt-list kt-list-ul">
                    <ListPoint>
                      <strong className="subheader">Innovation:</strong> Stay
                      ahead of the curve with our innovative technology
                      solutions.
                    </ListPoint>
                    <ListPoint>
                      <strong className="subheader">Expertise:</strong> Benefit
                      from our team's extensive knowledge and experience.
                    </ListPoint>
                    <ListPoint>
                      <strong className="subheader">Customization:</strong>{" "}
                      Tailor-made solutions to fit your specific business
                      requirements.
                    </ListPoint>
                    <ListPoint>
                      <strong className="subheader">Reliability:</strong> Depend
                      on us for reliable and secure technology services.
                    </ListPoint>
                    <ListPoint>
                      <strong className="subheader">Partnerships:</strong> We
                      connect you with trusted partners and providers for
                      comprehensive solutions.
                    </ListPoint>
                    <ListPoint>
                      <strong className="subheader">Results-driven:</strong> Our
                      focus is on driving tangible results and maximizing your
                      business potential.
                    </ListPoint>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section>
        <div className="col-md-12 mt-4 pt-4">
          <div className="content-text w-100 ms-auto text-center">
            <HStack
              className="subheader-color"
              vCentered
              styleProps={{ justifyContent: "center" }}
            >
              <MdPhone style={{ color: "#2B6CB0" }} /> Contact us today to
              embark on a transformative journey with The KT Platform. Together,
              let's unlock the power of technology to accelerate your success.
            </HStack>
            <HStack
              className="subheader-color mt-2"
              vCentered
              styleProps={{ justifyContent: "center" }}
            >
              <MdFrontHand style={{ color: "#2F855A" }} />
              Get in touch with us to discuss your technology needs and explore
              how we can assist you in achieving your business goals.
            </HStack>
          </div>
        </div>
      </section>
      <Footer />
    </div>
  );
};

export default Home;
