import Footer from "./Footer";
import Header from "./Header";

const BlogContainer = ({ children }) => {
  return (
    <div>
      <Header />
      <section className="kt-platform bg-tranh">
        <div className="container">
          <div className="row">
            <div className="col-md-12  mb-5">{children}</div>
          </div>
        </div>
      </section>
      <Footer />
    </div>
  );
};

export default BlogContainer;
