import React from "react";
import { BiLogoFacebook } from "react-icons/bi";
import { FaLinkedinIn, FaTwitter, FaYoutube } from "react-icons/fa";
import { Link } from "react-router-dom";
import footerlogo from "../../assets/logotransparent.png";
import "./style.scss";

const Footer = () => {
  return (
    <div>
      <footer>
        <div className="container">
          <div className="row">
            <div className="col-md-12 text-center">
              <div className="logos">
                <img src={footerlogo} alt="footer logo" />
              </div>
            </div>
          </div>
        </div>
        <hr />
        <div className="container">
          <div className="row">
            <div className="col-md-12 text-center">
              <div className="social-media">
                <ul>
                  <li>
                    <a
                      href="https://www.facebook.com/krysznatec"
                      target="_blank"
                      rel="noreferrer"
                    >
                      <BiLogoFacebook
                        style={{ color: "#4267B2" }}
                        className="icon"
                      />
                    </a>
                  </li>
                  <li>
                    <a
                      href="https://www.linkedin.com/company/kryszna-tec/"
                      target="_blank"
                      rel="noreferrer"
                    >
                      <FaLinkedinIn
                        style={{ color: "#0A66C2" }}
                        className="icon"
                      />
                    </a>
                  </li>
                  <li>
                    <a
                      href="https://twitter.com/KrysznaTec"
                      target="_blank"
                      rel="noreferrer"
                    >
                      <FaTwitter
                        style={{ color: "#1DA1F2" }}
                        className="icon"
                      />
                    </a>
                  </li>
                  <li>
                    <a
                      href="https://www.youtube.com/@KrysznaTec"
                      target="_blank"
                      rel="noreferrer"
                    >
                      <FaYoutube
                        style={{ color: "#FF0000" }}
                        className="icon"
                      />
                    </a>
                  </li>
                </ul>
                <p>© KRYSZNATEC 2022 We Love Our</p>
              </div>
            </div>
          </div>
        </div>

        <div className="container text-center">
          <Link className="policy-links" to="/privacy-policy">
            Privacy Policy
          </Link>{" "}
          |{" "}
          <Link className="policy-links" to="/cookie-policy">
            Cookie policy
          </Link>{" "}
          |{" "}
          <Link className="policy-links" to="/terms-and-conditions">
            Terms & Conditions
          </Link>
        </div>
      </footer>
    </div>
  );
};

export default Footer;
