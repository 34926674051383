import Footer from "../../components/Footer";
import Header from "../../components/Header";

const CookiePolicy = () => {
  return (
    <div>
      <Header />
      <section className="kt-platform bg-tranh">
        <div className="container">
          <div className="row">
            <div className="col-md-12  mb-5">
              <h1 className="text-center">Cookie Policy</h1>
            </div>
            <div className="col-md-12  mb-5">
              <h5>1. Introduction</h5>
              <p>
                Welcome to Krysznatec ("we", "us", "our"). Our website uses
                cookies to enhance your browsing experience. This Cookie Policy
                explains what cookies are, how we use them, and your choices
                regarding their use.
              </p>
            </div>
            <div className="col-md-12  mb-5">
              <h5>2. What are Cookies?</h5>
              <p>
                Cookies are small text files that are placed on your device
                (computer, smartphone, tablet) when you visit a website. They
                are widely used to make websites work more efficiently and to
                provide website owners with information about how their website
                is being used.
              </p>
            </div>
            <div className="col-md-12  mb-5">
              <h5>3. How We Use Cookies</h5>
              <p>We use cookies for the following purposes:</p>
              <ul>
                <li>
                  <b style={{ display: "contents" }}>3.1. Essential Cookies:</b>{" "}
                  These cookies are necessary for the functioning of our website
                  and cannot be switched off in our systems. They are usually
                  only set in response to actions made by you which amount to a
                  request for services, such as logging in or filling in forms.
                </li>
                <li>
                  <b style={{ display: "contents" }}>3.2. Analytics Cookies:</b>{" "}
                  These cookies allow us to track and analyze how users interact
                  with our website. This helps us improve the performance and
                  user experience of the site. The information collected by
                  these cookies is aggregated and anonymous.
                </li>
                <li>
                  <b style={{ display: "contents" }}>
                    3.3. Marketing Cookies:{" "}
                  </b>{" "}
                  We may also use cookies for marketing purposes to deliver
                  personalized advertisements and promotions based on your
                  interests and browsing behavior.
                </li>
              </ul>
            </div>
            <div className="col-md-12  mb-5">
              <h5>4. Your Consent</h5>
              <p>
                By using our website, you consent to the use of cookies as
                described in this Cookie Policy. You can manage your cookie
                preferences through the settings of your web browser. Please
                note that blocking certain types of cookies may impact your
                experience on our website.
              </p>
            </div>
            <div className="col-md-12  mb-5">
              <h5>5. Third-Party Cookies</h5>
              <p>
                We may also allow third-party services to place cookies on our
                website to enhance its functionality and provide additional
                features. These third-party cookies are subject to the
                respective privacy policies of the providers.
              </p>
            </div>
            <div className="col-md-12  mb-5">
              <h5>6. Changes to this Policy</h5>
              <p>
                We may update this Cookie Policy from time to time to reflect
                changes in our practices or for other operational, legal, or
                regulatory reasons. Any changes will be posted on this page, and
                the date of the latest revision will be indicated at the top of
                the policy.
              </p>
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </div>
  );
};

export default CookiePolicy;
