import {
  Routes,
  Route,
} from "react-router-dom";
import Home from './pages/Home';
import Pricing from './pages/Pricing';
import Services from './pages/Services';
import TechnologyAffiliates from './pages/TechnologyAffiliates';
import Blogs from './pages/Blogs';
import RightVendorBlog from "./pages/Blogs/RightVendorBlog";
import CloudComputingBlog from "./pages/Blogs/CloudComputingBlog";
import CostManagementBlog from "./pages/Blogs/CostManagementBlog";
import DigitalTransformationBlog from "./pages/Blogs/DigitalTransformationBlog";
import HybridCloudBlog from "./pages/Blogs/HybridCloudBlog";
import BestCloudComputingBlog from "./pages/Blogs/BestCloudComputingBlog";
import TermsAndCondition from "./pages/TermsAndCondition";
import PrivacyPolicy from "./pages/PrivacyPolicy";
import CookiePolicy from "./pages/CookiePolicy";

const AllRoutes = () => {
  return (
    <Routes>
      <Route path="/pricing" element={<Pricing />} />
      <Route path="/technology-affiliates" element={<TechnologyAffiliates />} />
      <Route path="/services" element={<Services />} />
      <Route path="/terms-and-conditions" element={<TermsAndCondition />} />
      <Route path="/privacy-policy" element={<PrivacyPolicy />} />
      <Route path="/cookie-policy" element={<CookiePolicy />} />
      <Route path="/blogs" element={<Blogs />} />
      <Route path="/blog/cloud-computing-trends-best-practices-stay-ahead-kt-platform" element={<CloudComputingBlog />} />
      <Route path="/blog/key-considerations-choosing-right-cloud-vendor-business" element={<RightVendorBlog />} />
      <Route path="/blog/cost-management-strategies-cloud-services" element={<CostManagementBlog />} />
      <Route path="/blog/digital-transformation-leveraging-technology-drive-business-growth" element={<DigitalTransformationBlog />} />
      <Route path="/blog/future-hybrid-cloud-advantages-implementation-tips-kt-platform" element={<HybridCloudBlog />} />
      <Route path="/blog/choose-best-cloud-computing-solution-business" element={<BestCloudComputingBlog />} />
      <Route path="/blog/latest-trends-innovations-cloud-technology-kt-platform" element={<Blogs />} />
      <Route path="/" element={<Home />} />
    </Routes>
  )
}

export default AllRoutes;