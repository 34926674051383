import React from "react";
import "./style.scss";
import logo from "../../assets/logotransparent.png";
import { Link } from "react-router-dom";
import Navbar from "react-bootstrap/Navbar";

const Header = () => {
  return (
    <div>
      <header>
        <div className="container">
          <Navbar expand="lg">
            <Link class="navbar-brand" to="/">
              {" "}
              <img src={logo} alt="Logo" />
            </Link>
            <Navbar.Toggle aria-controls="navbar-nav" />
            <Navbar.Collapse id="navbar-nav">
              <ul class="navbar-nav ms-auto me-auto mb-lg-0">
                <li class="nav-item icon-expand">
                  <Link class="nav-link" aria-current="page" to="/">
                    Home
                  </Link>
                </li>
                <li class="nav-item icon-expand">
                  <Link class="nav-link" to="/services">
                    Services
                  </Link>
                </li>
                <li class="nav-item icon-expand">
                  <Link class="nav-link" to="/pricing">
                    Pricing
                  </Link>
                </li>
                <li class="nav-item icon-expand">
                  <Link
                    class="nav-link"
                    aria-current="page"
                    to="/technology-affiliates"
                  >
                    Technology Affiliates
                  </Link>
                </li>
                <li class="nav-item icon-expand">
                  <Link class="nav-link" aria-current="page" to="/blogs">
                    Blogs
                  </Link>
                </li>
              </ul>
              <div className="login-register">
                <ul className="navbar-nav">
                  <li className="login">
                    <a
                      href={`${process.env.REACT_APP_KT_WEBSITE_BASE_URL}/login`}
                      style={{ textDecoration: "none" }}
                      rel="noreferrer"
                      target="_blank"
                    >
                      <button className="login-btn btn nav-link icon-expand">
                        Login
                      </button>
                    </a>
                  </li>
                  <li className="login">
                    <a
                      href={`${process.env.REACT_APP_KT_WEBSITE_BASE_URL}/registration`}
                      style={{ textDecoration: "none" }}
                      rel="noreferrer"
                      target="_blank"
                    >
                      <button className="login-btn btn register-btn icon-expand">
                        Register
                      </button>
                    </a>
                  </li>
                </ul>
              </div>
            </Navbar.Collapse>
          </Navbar>
        </div>
      </header>
    </div>
  );
};

export default Header;
