import Footer from "../../components/Footer";
import Header from "../../components/Header";

const PrivacyPolicy = () => {
  return (
    <div>
      <Header />
      <section className="kt-platform bg-tranh">
        <div className="container">
          <div className="row">
            <div className="col-md-12  mb-5">
              <h1 className="text-center">Privacy Policy</h1>
            </div>

            <div className="col-md-12  mb-5">
              <h5>1. Introduction</h5>
              <p>
                Welcome to Krysznatec ("we", "us", "our"). This Privacy Policy
                explains how we collect, use, disclose, and safeguard your
                personal information when you visit our website.
              </p>
            </div>
            <div className="col-md-12  mb-5">
              <h5>2. Information We Collect</h5>
              <p>
                We may collect certain personal information from you when you
                interact with our website. This information may include:
              </p>
              <p>
                <ul>
                  <li>
                    <b style={{ display: "contents" }}>
                      2.1. Contact Information:
                    </b>{" "}
                    such as your name, email address, and phone number.
                  </li>
                  <li>
                    <b style={{ display: "contents" }}>
                      2.2. Usage Information:
                    </b>{" "}
                    such as your IP address, device information, and browsing
                    behavior.
                  </li>
                </ul>
              </p>
            </div>
            <div className="col-md-12  mb-5">
              <h5>3. How We Use Your Information</h5>
              <p>
                We may use the information we collect for the following
                purposes:
              </p>

              <p>
                <ul>
                  <li>3.1. To provide and improve our services to you.</li>
                  <li>
                    3.2. To communicate with you regarding our products,
                    services, and promotions.
                  </li>
                  <li>
                    3.3. To analyze and understand how our website is used and
                    to improve its performance and user experience.
                  </li>
                </ul>
              </p>
            </div>
            <div className="col-md-12  mb-5">
              <h5>4. Sharing Your Information</h5>
              <p>
                We may share your personal information with third-party service
                providers and partners who assist us in operating our website
                and providing our services.
              </p>
              <p>
                We may also disclose your information in response to legal
                requirements, such as a court order or government request, or to
                protect our rights, property, or safety, or the rights,
                property, or safety of others.
              </p>
            </div>
            <div className="col-md-12  mb-5">
              <h5>5. Cookies and Similar Technologies</h5>
              <p>
                We use cookies and similar technologies to enhance your browsing
                experience on our website. You can manage your cookie
                preferences through your web browser settings.
              </p>
            </div>
            <div className="col-md-12  mb-5">
              <h5>6. Security of Your Information</h5>
              <p>
                We take reasonable measures to protect your personal information
                from unauthorized access, use, or disclosure. However, no method
                of transmission over the internet or electronic storage is
                completely secure, and we cannot guarantee the absolute security
                of your information.
              </p>
            </div>
            <div className="col-md-12  mb-5">
              <h5>7. Your Choices</h5>
              <p>
                You have the right to access, update, and delete your personal
                information.
              </p>
            </div>
            <div className="col-md-12  mb-5">
              <h5>8. Changes to this Policy</h5>
              <p>
                We may update this Privacy Policy from time to time to reflect
                changes in our practices or for other operational, legal, or
                regulatory reasons. Any changes will be posted on this page, and
                the date of the latest revision will be indicated at the top of
                the policy.
              </p>
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </div>
  );
};

export default PrivacyPolicy;
