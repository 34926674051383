import React from "react";
import blog from "../../assets/blogs-bg.png";
import BlogCard from "../../components/BlogCard";
import Footer from "../../components/Footer";
import Header from "../../components/Header";

const Blogs = () => {
  return (
    <div>
      <Header />

      <section className="banner techno mt-0 mb-5">
        <div className="banner-img">
          <div className="col-md-12 ps-0 pe-0">
            <div className="slider border-no">
              <img src={blog} alt="technology banner" />
            </div>
          </div>
        </div>
      </section>
      <section className="kt-platform bg-tranh">
        <div className="container">
          <div className="row">
            <div className="col-md-12 text-center mb-5">
              <div class="title" id="technology-affiliated-center">
                <h1 className="header-color">Welcome to Our Blogs Page</h1>
              </div>
            </div>
            <div className="row text-container">
              <div className="col-md-12 text-center mb-1 pb-4 subheader subheader-color">
                Explore our collection of insightful and informative blog posts
                covering a wide range of topics. Our team of experts has curated
                these articles to provide valuable knowledge and insights to
                help you stay informed and make informed decisions. Below, you
                will find a list of blog titles that cover various subjects
                relevant to your interests. Simply click on a title to dive into
                the full blog post and expand your knowledge. Enjoy your
                reading!
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="kt-platform bgone">
        <div className="container">
          <div className="row">
            <div className="col-md-12 text-center mb-5">
              <div class="title">
                <h3 className="header-color">Trending Blogs</h3>
              </div>
            </div>
            <div className="col-md-4 text-center mb-5">
              <BlogCard
                title="Cloud Computing Trends and Best Practices"
                subTitle="Jun 23 · 4 mins read"
                link="cloud-computing-trends-best-practices-stay-ahead-kt-platform"
              />
            </div>
            <div className="col-md-4 text-center mb-5">
              <BlogCard
                title="Choosing the Right Cloud Vendor for Your Business"
                subTitle="Jun 21 · 7 mins read"
                link="key-considerations-choosing-right-cloud-vendor-business"
              />
            </div>
            <div className="col-md-4 text-center mb-5">
              <BlogCard
                title="Cost Management Strategies for Cloud Services"
                subTitle="Jun 25 · 3 mins read"
                link="cost-management-strategies-cloud-services"
              />
            </div>
          </div>
        </div>
      </section>

      <section className="kt-platform bgtwo">
        <div className="container">
          <div className="row">
            <div className="col-md-12 text-center mb-5">
              <div class="title">
                <h2>All Blogs</h2>
              </div>
            </div>
            <div className="col-md-4 text-center mb-5">
              <BlogCard
                title="Cloud Computing Trends and Best Practices"
                subTitle="Jun 23 · 4 mins read"
                link="cloud-computing-trends-best-practices-stay-ahead-kt-platform"
              />
            </div>
            <div className="col-md-4 text-center mb-5">
              <BlogCard
                title="Choosing the Right Cloud Vendor for Your Business"
                subTitle="Jun 21 · 7 mins read"
                link="key-considerations-choosing-right-cloud-vendor-business"
              />
            </div>
            <div className="col-md-4 text-center mb-5">
              <BlogCard
                title="Cost Management Strategies for Cloud Services"
                subTitle="Jun 25 · 3 mins read"
                link="cost-management-strategies-cloud-services"
              />
            </div>
            <div className="col-md-4 text-center mb-5">
              <BlogCard
                title="Digital Transformation: Leveraging Technology to Drive Business Growth"
                subTitle="Jun 20 · 5 mins read"
                link="digital-transformation-leveraging-technology-drive-business-growth"
              />
            </div>
            <div className="col-md-4 text-center mb-5">
              <BlogCard
                title="The Future of Hybrid Cloud: Advantages and Implementation Tips"
                subTitle="Jun 19 · 4 mins read"
                link="future-hybrid-cloud-advantages-implementation-tips-kt-platform"
              />
            </div>
            <div className="col-md-4 text-center mb-5">
              <BlogCard
                title="How to choose the best cloud computing solution for your business"
                subTitle="Jun 20 · 6 mins read"
                link="choose-best-cloud-computing-solution-business"
              />
            </div>
            <div className="col-md-4 text-center mb-5">
              <BlogCard
                title="The latest trends and innovations in cloud technology"
                subTitle="Jun 21 · 5 mins read"
                link="latest-trends-innovations-cloud-technology-kt-platform"
              />
            </div>
          </div>
        </div>
      </section>

      <Footer />
    </div>
  );
};

export default Blogs;
