import "./style.scss";

const ElevatedCard = ({ title, content }) => {
  return (
    <div className="elevated-div">
      {title && <h4 className="header-color">{title}</h4>}
      <div className="subheader-color">{content}</div>
    </div>
  );
};

export default ElevatedCard;
