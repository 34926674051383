import { Link } from "react-router-dom";

const BlogCard = ({ title, subTitle, link }) => {
  return (
    <div>
      <Link
        to={`/blog/${link}`}
        style={{ textDecoration: "none", color: "#3182CE" }}
      >
        <div
          style={{
            textAlign: "left",
            fontSize: "20px",
            cursor: "pointer",
            fontWeight: 600,
          }}
          className="expand"
        >
          {title}
        </div>
      </Link>
      <div style={{ textAlign: "left" }} className="subheader-color">
        {subTitle}
      </div>
    </div>
  );
};

export default BlogCard;
